import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    $('.parallax').each(function () {
      var layer = $(this);
      var dataSpeed = layer.data('parallax-speed');
      var offsetY = -(scroll * dataSpeed / 2);
      if ($(this).attr("id") == "hero-title") {
        offsetY = offsetY * -1;
      }
      var translate = 'translate3d(0, ' + offsetY + 'px, 0)';
      layer.css('-webkit-transform', translate);
      layer.css('-moz-transform', translate);
      layer.css('-ms-transform', translate);
      layer.css('-o-transform', translate);
      layer.css('transform', translate);
    });
  });

